import './Spinner.svelte.css';
/* src/components/Spinner.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div5;
	let div3;
	let t2;
	let div4;
	let t3;

	return {
		c() {
			div5 = element("div");
			div3 = element("div");

			div3.innerHTML = `<div class="bounce1 svelte-pi9n6g"></div> 
    <div class="bounce2 svelte-pi9n6g"></div> 
    <div class="bounce3 svelte-pi9n6g"></div>`;

			t2 = space();
			div4 = element("div");
			t3 = text(/*message*/ ctx[0]);
			attr(div3, "class", "spinner svelte-pi9n6g");
			attr(div4, "class", "text-center display-6 my-4");
			attr(div5, "class", "absolute-center svelte-pi9n6g");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div3);
			append(div5, t2);
			append(div5, div4);
			append(div4, t3);
		},
		p(ctx, [dirty]) {
			if (dirty & /*message*/ 1) set_data(t3, /*message*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div5);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { message = "Processing..." } = $$props;

	$$self.$$set = $$props => {
		if ('message' in $$props) $$invalidate(0, message = $$props.message);
	};

	return [message];
}

class Spinner extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { message: 0 });
	}
}

export default Spinner;