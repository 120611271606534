import './DownloadButton.svelte.css';
/* src/components/DownloadButton.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { downloadBlob } from './DownloadButton.js';

function create_fragment(ctx) {
	let div;
	let button;
	let t;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			t = text(/*buttonText*/ ctx[0]);
			button.disabled = /*buttonDisabled*/ ctx[1];
			attr(div, "class", "svelte-1qteyr2");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);
			append(button, t);

			if (!mounted) {
				dispose = listen(button, "click", /*hc*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*buttonText*/ 1) set_data(t, /*buttonText*/ ctx[0]);

			if (dirty & /*buttonDisabled*/ 2) {
				button.disabled = /*buttonDisabled*/ ctx[1];
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { searchResult } = $$props;
	let f, blobName, buttonText, buttonDisabled;

	const hc = async () => {
		$$invalidate(1, buttonDisabled = true);
		$$invalidate(0, buttonText = 'Downloading...');
		await downloadBlob(blobName);

		setTimeout(
			function () {
				$$invalidate(0, buttonText = 'Download');
				$$invalidate(1, buttonDisabled = false);
			},
			2000
		);
	};

	$$self.$$set = $$props => {
		if ('searchResult' in $$props) $$invalidate(3, searchResult = $$props.searchResult);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*searchResult*/ 8) {
			$: $$invalidate(4, f = searchResult['74']);
		}

		if ($$self.$$.dirty & /*f*/ 16) {
			$: blobName = [f.substring(0, 3), f.substring(3, 6), f].join('/');
		}
	};

	$: $$invalidate(0, buttonText = 'Download');
	$: $$invalidate(1, buttonDisabled = false);
	return [buttonText, buttonDisabled, hc, searchResult, f];
}

class DownloadButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { searchResult: 3 });
	}
}

export default DownloadButton;