/* src/App.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	empty,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Content from './Content.svelte.js';
import Spinner from './components/Spinner.svelte.js';
import { InteractiveBrowserCredential as ibc } from '../_snowpack/pkg/@azure/identity.js';
import { login } from './stores/config/config.js';

function create_catch_block(ctx) {
	return {
		c: noop,
		m: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (10:0) {:then _}
function create_then_block(ctx) {
	let content;
	let current;
	content = new Content({});

	return {
		c() {
			create_component(content.$$.fragment);
		},
		m(target, anchor) {
			mount_component(content, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(content.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(content.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(content, detaching);
		}
	};
}

// (8:36)    <Spinner message={'Logging in...'}
function create_pending_block(ctx) {
	let spinner;
	let current;
	spinner = new Spinner({ props: { message: 'Logging in...' } });

	return {
		c() {
			create_component(spinner.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spinner, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spinner, detaching);
		}
	};
}

function create_fragment(ctx) {
	let await_block_anchor;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 0,
		blocks: [,,,]
	};

	handle_promise(promise = new ibc(login).getToken(''), info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default App;