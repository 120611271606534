import './SearchResult.svelte.css';
/* src/components/SearchResult.svelte generated by Svelte v3.55.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import DownloadButton from './DownloadButton.svelte.js';
import docProps from '../stores/preferences/docProps.js';
import docTypes from '../stores/preferences/docTypes.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (42:6) {#if propKeyToName(key)}
function create_if_block(ctx) {
	let b;
	let t0_value = /*propKeyToName*/ ctx[1](/*key*/ ctx[3]) + "";
	let t0;
	let t1;
	let t2_value = /*searchResult*/ ctx[0][/*key*/ ctx[3]] + "";
	let t2;
	let t3;
	let br;

	return {
		c() {
			b = element("b");
			t0 = text(t0_value);
			t1 = text("\n        : ");
			t2 = text(t2_value);
			t3 = space();
			br = element("br");
		},
		m(target, anchor) {
			insert(target, b, anchor);
			append(b, t0);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
			insert(target, t3, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*searchResult*/ 1 && t0_value !== (t0_value = /*propKeyToName*/ ctx[1](/*key*/ ctx[3]) + "")) set_data(t0, t0_value);
			if (dirty & /*searchResult*/ 1 && t2_value !== (t2_value = /*searchResult*/ ctx[0][/*key*/ ctx[3]] + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(b);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
			if (detaching) detach(t3);
			if (detaching) detach(br);
		}
	};
}

// (41:4) {#each Object.keys(searchResult) as key}
function create_each_block(ctx) {
	let show_if = /*propKeyToName*/ ctx[1](/*key*/ ctx[3]);
	let if_block_anchor;
	let if_block = show_if && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*searchResult*/ 1) show_if = /*propKeyToName*/ ctx[1](/*key*/ ctx[3]);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let p0;
	let b;
	let t0_value = /*typeKeyToName*/ ctx[2](/*searchResult*/ ctx[0]['72']) + "";
	let t0;
	let t1;
	let p1;
	let t2;
	let p2;
	let downloadbutton;
	let current;
	let each_value = Object.keys(/*searchResult*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	downloadbutton = new DownloadButton({
			props: { searchResult: /*searchResult*/ ctx[0] }
		});

	return {
		c() {
			div = element("div");
			p0 = element("p");
			b = element("b");
			t0 = text(t0_value);
			t1 = space();
			p1 = element("p");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			p2 = element("p");
			create_component(downloadbutton.$$.fragment);
			attr(p0, "class", "head svelte-1u11w2h");
			attr(p1, "class", "body svelte-1u11w2h");
			attr(p2, "class", "foot svelte-1u11w2h");
			attr(div, "class", "zoom svelte-1u11w2h");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p0);
			append(p0, b);
			append(b, t0);
			append(div, t1);
			append(div, p1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(p1, null);
			}

			append(div, t2);
			append(div, p2);
			mount_component(downloadbutton, p2, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*searchResult*/ 1) && t0_value !== (t0_value = /*typeKeyToName*/ ctx[2](/*searchResult*/ ctx[0]['72']) + "")) set_data(t0, t0_value);

			if (dirty & /*searchResult, Object, propKeyToName*/ 3) {
				each_value = Object.keys(/*searchResult*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(p1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			const downloadbutton_changes = {};
			if (dirty & /*searchResult*/ 1) downloadbutton_changes.searchResult = /*searchResult*/ ctx[0];
			downloadbutton.$set(downloadbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(downloadbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(downloadbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			destroy_component(downloadbutton);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { searchResult } = $$props;
	const propKeyToName = k => docProps.find(x => x.id == k)?.name;
	const typeKeyToName = k => docTypes.find(x => x.id == k)?.name;

	$$self.$$set = $$props => {
		if ('searchResult' in $$props) $$invalidate(0, searchResult = $$props.searchResult);
	};

	return [searchResult, propKeyToName, typeKeyToName];
}

class SearchResult extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { searchResult: 0 });
	}
}

export default SearchResult;